// Icons
// @import "icons/dripicons";
@import "icons/materialdesignicons";
// @import "icons/feather";
// @import "icons/themify-icons";
// @import "icons/fontawesome-all";
// @import "icons/simple-line-icons";
// @import "icons/weather-icons";
// @import "icons/bootstrap-icons";

// Google Icon
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
